<template>
  <div>
    <CCardBody class="c-card-body">
      <router-link :to="`/orders/create`">
        <CButton color="success">Создать заказ</CButton>
      </router-link>
      <br />
      <br />
      <CDataTable
          :items="orders"
          :fields="fields"
          :sorter="{ external: true, resetable: true }"
          :table-filter="{ label: 'Поиск', placeholder: 'Начните писать...', external: true }"
          :sorter-value="sorter"
          :table-filter-value="searchString"
          hover
          border
          @update:sorter-value="updateSorter"
          @update:table-filter-value="updateSearchString"
      >
        <template #footer>
          <br />
          <CPagination 
            :active-page="page"
            :pages="pages"
            @update:activePage="toPage"
          />
        </template>
        <template #code="{ item }">
          <td class="td">
            <router-link :to="`/orders/${item.id}/plan`">
              {{ item.code }}
            </router-link>
          </td>
        </template>
        <template #user="{ item }">
          <td class="td">
              {{ item.user.first_name }}

          </td>
        </template>
        <template #created="{ item }">
          <td class="td">
            {{ getDate(item) }}
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </div>
</template>

<style>
.test {
  background-color: red;
}

.c-card-body {
  background-color: #fefefe;
}

table {
  font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
  font-size: 14px;
  background: white;
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

th {
  font-weight: normal;
  color: #039;
  border-bottom: 2px dashed #6678b1;
  padding: 10px 8px;
}

.td {
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}

tr:hover .td {
  background: #e8edff;
}

.td {
  border-bottom: 0.5px dashed #ccc;
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}

th:nth-child(-n + 3),
.td:nth-child(-n + 3) {
  text-align: left;
}

.item-right {
  text-align: right;
}
</style>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { debounce } from "lodash";

const fields = [
  {
    key: "code",
    label: "Код заказа",
  },
  {
    key: "created",
    label: "Дата создания",
  },
  {
    key: "status",
    label: "Состояние",
  },
  {
    key: "client",
    label: "Покупатель",
  },
  {
    key: "user",
    label: "Менеджер",
  },
];

export default {
  name: "Orders",
  data() {
    return {
      fields,
      sorter: { column: "created", asc: false },
      searchString: undefined,
    };
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders.ordersOnPage,
      page: (state) => state.orders.page
    }),
    ...mapGetters({pages: "orders/pages"}),
    params () {
      const result = {}

      if (this.searchString) result.search = this.searchString
      if (this.sorter.column) result["sort-by"] = `${this.sorter.asc ? "" : "-"}${this.sorter.column}`

      return result
    }
  },
  watch: {
    params: {
      handler (newValue)  {
        this["orders/getPage"]({ page: 1, params: newValue });
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapActions(["orders/getPage"]),
    getDate (item) {
      return new Intl.DateTimeFormat("ru-RU").format(new Date(item.created));
    },
    toPage (page) {
      this['orders/getPage']({ page, params: this.params });
    },
    updateSorter( sorter) {
      this.sorter = sorter
    },
    updateSearchString: debounce(function (searchString) {
      this.searchString = searchString
    }, 500)
  },
};
</script>
